<template>
  <div>
    <!--<h1>市場報價</h1>
  <div class="page-content">
    {{ marketRate }}
  </div>-->
    <div class="market-news">
      <b-container class="my-4">
        <h1 class="my-4" v-if="page">{{ page.title }}</h1>
        <b-tabs content-class="mt-3" class="news-tabs" lazy justified align="center" v-model="tabsIndex">
          <b-tab>
            <template v-slot:title>
              <div class="d-flex align-items-center justify-content-center">
                <strong>{{ $t('外幣兑港元匯價') }}</strong>
              </div>
            </template>
            <template v-if="content">
              <div class="rate">
                <p class="update-time my-4">{{ $t('網頁更新時間') }}:{{ content.inputAt }}</p>
                <table class="container bars">
                  <thead>
                    <tr>
                      <th width="30%">{{ $t('貨幣') }}</th>
                      <th>{{ $t('買入') }}</th>
                      <th>{{ $t('賣出') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rate, index) in content.exHkRate" :key="`${rate.slug}-${index}`">
                      <td :data-th="$t('貨幣')">
                        <div class="d-flex align-items-center justify-content-start flag-div">
                          <b-img
                            :src="rate.icon || flagImg(rate.slug)"
                            sizes="small"
                            class="col-4 col-md-2 flag-img"
                            :alt="langName(rate.name)"
                          ></b-img>
                          {{ langName(rate.name) }}
                        </div>
                      </td>
                      <td :data-th="$t('買入')">
                        {{ rate.buy }}
                      </td>
                      <td :data-th="$t('賣出')">
                        {{ rate.sell }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <div class="d-flex align-items-center justify-content-center">
                <strong>{{ $t('外幣兑澳門元匯價') }}</strong>
              </div>
            </template>
            <template v-if="content">
              <div class="rate">
                <p class="update-time my-4">{{ $t('網頁更新時間') }}:{{ content.inputAt }}</p>
                <table class="container bars">
                  <thead>
                    <tr>
                      <th width="30%">{{ $t('貨幣') }}</th>
                      <th>{{ $t('買入') }}</th>
                      <th>{{ $t('賣出') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rate, index) in content.exMopRate" :key="`${rate.slug}-${index}`">
                      <td :data-th="$t('貨幣')">
                        <div class="d-flex align-items-center justify-content-start flag-div">
                          <b-img
                            :src="rate.icon || flagImg(rate.slug)"
                            sizes="small"
                            class="col-6 col-md-3 flag-img"
                            :alt="langName(rate.name)"
                          ></b-img>
                          {{ langName(rate.name) }}
                        </div>
                      </td>
                      <td :data-th="$t('買入')">
                        {{ rate.buy }}
                      </td>
                      <td :data-th="$t('賣出')">
                        {{ rate.sell }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <div class="d-flex align-items-center justify-content-center">
                <strong>{{ $t('外幣兌港元鈔價') }}</strong>
              </div>
            </template>
            <template v-if="content">
              <div class="rate">
                <p class="update-time my-4">{{ $t('網頁更新時間') }}:{{ content.inputAt }}</p>
                <table class="container bars">
                  <thead>
                    <tr>
                      <th width="30%">{{ $t('貨幣') }}</th>
                      <th>{{ $t('買入') }}</th>
                      <th>{{ $t('賣出') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rate, index) in content.noteHkRate" :key="`${rate.slug}-${index}`">
                      <td :data-th="$t('貨幣')">
                        <div class="d-flex align-items-center justify-content-start flag-div">
                          <img
                            :src="rate.icon || flagImg(rate.slug)"
                            sizes="small"
                            class="col-6 col-md-3 flag-img"
                            :alt="langName(rate.name)"
                          />
                          {{ langName(rate.name) }}
                        </div>
                      </td>
                      <td :data-th="$t('買入')">
                        {{ rate.buy }}
                      </td>
                      <td :data-th="$t('賣出')">
                        {{ rate.sell }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <div class="d-flex align-items-center justify-content-center">
                <strong>{{ $t('外幣兌澳門元鈔價') }}</strong>
              </div>
            </template>
            <template v-if="content">
              <div class="rate">
                <p class="update-time my-4">{{ $t('網頁更新時間') }}:{{ content.inputAt }}</p>
                <table class="container bars">
                  <thead>
                    <tr>
                      <th width="30%">{{ $t('貨幣') }}</th>
                      <th>{{ $t('買入') }}</th>
                      <th>{{ $t('賣出') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rate, index) in content.noteMopRate" :key="`${rate.slug}-${index}`">
                      <td :data-th="$t('貨幣')">
                        <div class="d-flex align-items-center justify-content-start flag-div">
                          <img
                            :src="rate.icon || flagImg(rate.slug)"
                            sizes="small"
                            class="col-6 col-md-3 flag-img"
                            :alt="langName(rate.name)"
                          />
                          {{ langName(rate.name) }}
                        </div>
                      </td>
                      <td :data-th="$t('買入')">
                        {{ rate.buy }}
                      </td>
                      <td :data-th="$t('賣出')">
                        {{ rate.sell }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <div class="d-flex align-items-center justify-content-center">
                <strong>{{ $t('外幣兑美元匯價') }}</strong>
              </div>
            </template>
            <template v-if="content">
              <div class="rate">
                <p class="update-time my-4">{{ $t('網頁更新時間') }}:{{ content.inputAt }}</p>
                <table class="container bars">
                  <thead>
                    <tr>
                      <th width="30%">{{ $t('貨幣') }}</th>
                      <th>{{ $t('買入') }}</th>
                      <th>{{ $t('賣出') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rate, index) in content.exUsdRate" :key="`${rate.slug}-${index}`">
                      <td :data-th="$t('貨幣')">
                        {{ langName(rate.name) }}
                      </td>
                      <td :data-th="$t('買入')">
                        {{ rate.buy }}
                      </td>
                      <td :data-th="$t('賣出')">
                        {{ rate.sell }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <div class="d-flex align-items-center justify-content-center">
                <strong>{{ $t('存款利率') }}</strong>
              </div>
            </template>
            <template v-if="content">
              <div class="rate">
                <p class="update-time my-4">{{ $t('網頁更新時間') }}:{{ content.inputAt }}</p>
                <table class="container bars">
                  <thead>
                    <tr>
                      <th>{{ $t('貨幣') }}</th>
                      <th>{{ $t('儲蓄存款') }}</th>
                      <th>{{ $t('一星期') }}</th>
                      <th>{{ $t('二星期') }}</th>
                      <th>{{ $t('一個月') }}</th>
                      <th>{{ $t('二個月') }}</th>
                      <th>{{ $t('三個月') }}</th>
                      <th>{{ $t('六個月') }}</th>
                      <th>{{ $t('十二個月') }}</th>
                      <th>{{ $t('十五個月') }}</th>
                      <th>{{ $t('十八個月') }}</th>
                      <th>{{ $t('豐收存款') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rate, index) in content.saveRate" :key="`${rate.slug}-${index}`">
                      <td :data-th="$t('貨幣')">
                        {{ langName(rate.name) }}
                      </td>
                      <td :data-th="$t('儲蓄存款')">
                        {{ rate.save_rage }}
                      </td>
                      <td :data-th="$t('一星期')">
                        {{ rate['1-week'] }}
                      </td>
                      <td :data-th="$t('二星期')">
                        {{ rate['2-week'] }}
                      </td>
                      <td :data-th="$t('一個月')">
                        {{ rate['1-month'] }}
                      </td>
                      <td :data-th="$t('二個月')">
                        {{ rate['2-month'] }}
                      </td>
                      <td :data-th="$t('三個月')">
                        {{ rate['3-month'] }}
                      </td>
                      <td :data-th="$t('六個月')">
                        {{ rate['6-month'] }}
                      </td>
                      <td :data-th="$t('十二個月')">
                        {{ rate['12-month'] }}
                      </td>
                      <td :data-th="$t('十五個月')">
                        {{ rate['15-month'] }}
                      </td>
                      <td :data-th="$t('十八個月')">
                        {{ rate['18-month'] }}
                      </td>
                      <td :data-th="$t('豐收存款')">
                        {{ rate.premium_deposit }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </b-tab>
        </b-tabs>
        <div class="tips">*{{ $t('以上資料僅供參考(交易時以本行報價為凖)') }}</div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
import { lanToISO639, ISO639ToLan } from '@/utils'

export default {
  name: 'marketrate',
  data() {
    return {
      tabsIndex: 0,
      content: null
    }
  },
  watch: {
    tabsIndex(o, n) {
      // console.log(o, n)
    }
  },
  mounted() {
    this.fetchData()
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  computed: {
    ...mapState('page', ['marketRate']),
    ...mapState('common', ['page'])
  },
  methods: {
    flagImg(src) {
      try {
        return `${require('@/assets/images/flag/' + src + '.png')}`
      } catch {
        return `${require('@/assets/images/flag/TFG.png')}`
      }
    },
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_MARKET_RATE', {
            cookies,
            preview,
            id
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    hanldeInit() {
      this.content = this.marketRate
      this.handleTime()
    },
    langName(name) {
      return name[lanToISO639(this.$route.params.lang)]
    },
    handleTime() {
      setTimeout(e => {
        this.fetchData()
      }, 60000)
    }
  }
}
</script>
